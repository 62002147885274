/**
 * App Config File
 */

const staticFileBasePath = '/static/'

export default {
  staticImagesBasePath: `${staticFileBasePath}img/`,
  appLogo: `${staticFileBasePath}img/appLogo.png`,
  appLogoSignup: `${staticFileBasePath}img/appLogo.png`,
  stunnrAppLogo: `${staticFileBasePath}img/stunnr-app-logo.svg`,
  stunnrAppLogoIcon: `${staticFileBasePath}img/stunnr-app-logo-icon.svg`,
  pageNotFoundImage: `${staticFileBasePath}img/not-found.png`,
  shopifyLogo: `${staticFileBasePath}img/shopify-logo.png`,
  magentoLogo: `${staticFileBasePath}img/magento-logo.png`,
  wooCommerceLogo: `${staticFileBasePath}img/woocommerce-logo.png`,
  bigCommerceLogo: `${staticFileBasePath}img/big-commerce-logo.png`,
  facebookLogo: `${staticFileBasePath}img/facebook-logo.png`,
  googleMerchantCenterLogo: `${staticFileBasePath}img/google-merchant-center-logo.png`,
  googleSignIn: `${staticFileBasePath}img/google-sign-in.png`,
  googleSignInSmall: `${staticFileBasePath}img/google-sign-in-small.png`,
  embedGetStarted: `${staticFileBasePath}img/get-started.png`,
  copyrightText: 'Copyright @ $$year$$ MediaBuy Pte. Ltd.',
  brand: 'Xpresso',
  logoStoragePath: `${process.env.VUE_APP_STORAGE_URL}/AllFiles/Uploads/Logos/`,
  galleryStoragePath: `${process.env.VUE_APP_STORAGE_URL}/AllFiles/Uploads/Images/Gallery/`,
  stripeKey: process.env.VUE_APP_STRIPE_PUBLISHER_KEY,
  facebookGraphApiVersion: 'v8.0',
  minimumRechargeInUSD: 5,
  email: process.env.VUE_APP_EMAIL,
  callBackUrlForMagento: process.env.VUE_APP_CALLBACK_URL_MAGENTO,
  identityUrl: process.env.VUE_APP_IDENTITY_URL,
  googleMerchantCenterRedirectUrl:
        process.env.VUE_APP_GOOGLE_MERCHANT_CENTER_REDIRECT_URL,
  onboardingGoogleMerchantCenterRedirectUrl:
        process.env.VUE_APP_ONBOARDING_GOOGLE_MERCHANT_CENTER_REDIRECT_URL,
  shopifyRedirectUrl: process.env.VUE_APP_SHOPIFY_REDIRECT_URL,
  chatWindowUrl: 'https://wchat.freshchat.com',
  chatWindowToken: 'b24be9f0-7742-4dc2-8c79-9f84e9617897',
  bigCommerceOauthUrl: process.env.VUE_APP_BIGCOMMERCE_OAUTH_URL,
  sampleImagesStoragePath: `${process.env.VUE_APP_STORAGE_URL}/AllFiles/Uploads/Roimatic/Demo`,
  sampleVideosStoragePath: `${process.env.VUE_APP_STORAGE_URL}/AllFiles/Uploads/Roimatic/Demo`,
  bugsnagApiKey: '2e674ff0ba7f3d9ee90a4347e3497465',
  razorpayKey: process.env.VUE_APP_RAZORPAY_KEY,
  imageNotAvailable: '/static/img/image-not-available.png',
  appName: process.env.VUE_APP_APPNAME
};