import api from 'Api';
import { compare } from 'fast-json-patch';
import { Outlet, CategoryTypes, Categories, StoreTypes, Stores } from 'Constants/storeTypes';

export class RoimaticService {
  constructor(businessProfileId){
    this.businessProfileId = businessProfileId;
  }

  async setupCreativeBuilder(name, currencyId = 17, timeZoneId = 142,  referralCode, completeStoreDomain) {
    var postData = {
      name,
      currencyId,
      timeZoneId,
      IsRoimatic: true,
      referralCode,
      completeStoreDomain,
      role: 4,
      outlet: Outlet,
      category: CategoryTypes.find(ct => ct.id == Categories.CreativeBuilder).category,
      flavour: StoreTypes.find(st => st.id == Stores.CreativeBuilder).store
    }
    let response = await api.post('/roimatic/setupCreativeBuilder', postData)
    return response.data.data;
  }

  async getRoimaticConfiguration() {
    var response = await api.get(`/businessProfiles/${this.businessProfileId}/roimatic`);
    return response.data.data;
  }

  async updateConfiguration(oldConfig, newConfig){
    var patch = compare(oldConfig, newConfig);
    await api.patch(`/businessProfiles/${this.businessProfileId}/roimatic`, patch);
  }

  async getPromotions(flavour) {
    var response = await api.get(`/businessProfiles/${this.businessProfileId}/roimatic/promotions`, {
      params: {
        flavour
      }
    });
    return response.data.data;
  }

  async getPromoCreatives(promotionId) {
    var response = await api.get(`/businessProfiles/${this.businessProfileId}/roimatic/getCreatives`, {
      params: {
        promotionId
      }
    });
    return response.data.data;
  }
  async getCategoryCreatives(category) {
    var response = await api.get(`/businessProfiles/${this.businessProfileId}/roimatic/getCategoryCreatives`, {
      params: {
        category
      }
    });
    return response.data.data;
  }

  async getCampaigns(mediapLanIds, drafts) {
    let params = {};
    if (mediapLanIds)
    {
      params['mediapLanIds'] = mediapLanIds;
    }
    if (drafts)
    {
      params['IsDraft'] = drafts;
    }
    var response = await api.get(`/businessProfiles/${this.businessProfileId}/roimatic/campaigns`, {
      params
    });
    return response.data.data;
  }

  async createCampaigns(campaign) {
    var response = await api.post(`/businessProfiles/${this.businessProfileId}/roimatic/campaigns`, campaign);
    return response.data.data;
  }

  async updateCampaigns(campaignId, data) {
    var response = await api.patch(`/businessProfiles/${this.businessProfileId}/roimatic/campaigns/${campaignId}`,
      data, {
        headers: {
          'Content-type': 'application/json'
        }
      });
    return response.data.data;
  }
}
